import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';
const SignUp = lazy(() => import('../container/profile/authentication/overview/Signup'));
const ForgotPass = lazy(() => import('../container/profile/authentication/overview/ForgotPassword'));
const OneTimePass = lazy(() => import('../container/profile/authentication/overview/OneTimePassword'));
const ResetPass = lazy(() => import('../container/profile/authentication/overview/ResetPassword'));
const Exam = lazy(() => import('../container/pages/Exam'));
const PracticeSet = lazy(() => import('../container/pages/PracticeSet'));
const ExamSuccess = lazy(() => import('../container/pages/ExamSuccess'));
const QuizSuccess = lazy(() => import('../container/pages/QuizSuccess'));
const PracticeSuccess = lazy(() => import('../container/pages/PracticeSetSuccess'));
const Quiz = lazy(() => import('../container/pages/Quiz'));
const Analysis = lazy(() => import('./../container/pages/Analysis'));
const ViewResults = lazy(() => import('./../container/pages/ViewResults'));
const OverViewQuiz = lazy(() => import('./../container/pages/OverViewQuiz'));
const OverViewExam = lazy(() => import('./../container/pages/OverViewExam'));
const OverViewPractice = lazy(() => import('./../container/pages/OverViewPracticeSet'));
const login = lazy(() => import('./../container/profile/authentication/overview/SignIn'));
const Chatboard = lazy(() => import('../container/pages/ChatBoard'));
const ApplicationAi = lazy(() => import('../container/pages/ApplicationAi'));
const EdiApplication = lazy(() => import('../container/pages/EdiApplication'));
const LeadsAi = lazy(() => import('../container/pages/LeadsAi'));
const LeadsById = lazy(() => import('../container/pages/LeadsById'));
const RedirectQr = lazy(() => import('../container/pages/RedirectQr.js'));
const Guide = lazy(() => import('../container/pages/Guide.js'));
console.log('AUTH');
const NotFound = () => {
  console.log('not found');
  return <Redirect to="/" />;
};
// const RedirectToDashboard = ()=>{

// }
const FrontendRoutes = () => {
  console.log('frontend route');
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={`/qr`} component={RedirectQr} />
        <Route exact path="/exam/:id" component={Exam} />
        <Route exact path="/practice-sets/:id" component={PracticeSet} />
        <Route exact path="/quiz/:id" component={Quiz} />
        <Route exact path="/exam-success" component={ExamSuccess} />
        <Route exact path="/quiz-success" component={QuizSuccess} />
        <Route exact path="/practice-sets-success" component={PracticeSuccess} />
        <Route exact path="/application_ai" component={ApplicationAi} />
        <Route exact path="/Analysis" component={Analysis} />
        <Route exact path="/viewResults" component={ViewResults} />
        {/* <Route exact path="/" component={ApplicationAi} /> */}
        <Route path={`/editapplication/:id`} component={EdiApplication} />
        <Route path={`/getleads/:id`} component={LeadsById} />
        <Route path="/leadsai" component={LeadsAi} />
        <Route exact path="/overview-quiz/:id" component={OverViewQuiz} />
        <Route exact path="/chatboard" component={Chatboard} />
        <Route exact path="/overview-exam/:id" component={OverViewExam} />
        <Route exact path="/overview-practice-set/:id" component={OverViewPractice} />
        <Route exact path="/" component={login} />
        <Route exact path="/forgotPassword" component={ForgotPass} />
        <Route exact path="/oneTimePassword" component={OneTimePass} />
        <Route exact path="/resetPassword/:id" component={ResetPass} />
        <Route exact path="/register" component={SignUp} />
        <Route exact path="/guide/:id" component={Guide} />
      </Suspense>
    </Switch>
  );
};

export default AuthLayout(FrontendRoutes);
